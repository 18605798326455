//Expand for desktop
window.expandMemberDescription = function(position) {
  teamMembers = document.querySelectorAll(".homepage--team--image");
  teamDescriptions = document.querySelectorAll(
    ".col-lg-6.homepage--team--description"
  );
  position = parseInt(position);
  seeMore = document.querySelector(".homepage-team--see-more");

  if (seeMore) {
    position === 8 ? addClassHidden(seeMore) : removeClassHidden(seeMore);
  }
  calculateExpansionForDesktop(position);
};

window.expandMemberDescriptionTeam = function(position) {
  teamMembers = document.querySelectorAll(".homepage--team--image");
  teamDescriptions = document.querySelectorAll(
    ".col-lg-6.homepage--team--description"
  );
  position = parseInt(position);

  calculateExpansionForDesktopTeam(position);
};

// Expand for tablet - always put first in the row the one you just clicked
window.expandMemberDescriptionTablet = function(position) {
  teamMembers = document.querySelectorAll(".homepage--team--image-tablet");
  teamDescriptions = document.querySelectorAll(
    ".homepage--team--description-tablet"
  );
  position = parseInt(position);
  seeMore = document.querySelector(".homepage-team--see-more-tablet");

  if (seeMore) {
    [9, 10].includes(position)
      ? addClassHidden(seeMore)
      : removeClassHidden(seeMore);
  }

  if (position % 3 === 0) {
    addClassesToTeam(position, position + 1, position + 2);
  } else if (position % 3 === 1) {
    addClassesToTeam(position, position - 1, position + 1);
  } else {
    addClassesToTeam(position, position - 1, position - 2);
  }
};

// Expand for mobile
window.expandMemberDescriptionMobile = function(position) {
  teamMembersMobile = document.querySelectorAll(
    ".homepage--team--image-mobile"
  );
  teamDescriptionsMobile = document.querySelectorAll(
    ".homepage--team--description-mobile"
  );
  position = parseInt(position);

  seeMore = document.querySelector(".homepage-team--see-more-mobile");

  teamDescriptionsMobile.forEach(function(teamDescriptionMobile) {
    if (teamDescriptionsMobile[position] === teamDescriptionMobile) {
      removeClassHidden(teamDescriptionMobile);
    } else {
      addClassHidden(teamDescriptionMobile);
    }
  });

  if (position % 2 === 0 && position !== 10) {
    window.collapseMemberDescriptionMobile(position, position + 1);
    addClassHidden(seeMore);
  } else if (position === 10) {
    window.collapseMemberDescriptionMobile(position, position + 1);
    addClassHidden(seeMore);
  } else {
    window.collapseMemberDescriptionMobile(position, position - 1);
    removeClassHidden(seeMore);
  }
};

// Revert the expanding of the member description section
window.collapseMemberDescription = function() {
  teamMembers = document.querySelectorAll(".homepage--team--image");
  teamMembersTablet = document.querySelectorAll(
    ".homepage--team--image-tablet"
  );
  teamDescriptions = document.querySelectorAll(".homepage--team--description");
  teamDescriptionsTablet = document.querySelectorAll(
    ".homepage--team--description-tablet"
  );
  seeMores = document.querySelectorAll(".homepage-team--see-more");

  seeMores.forEach(function(seeMore) {
    removeClassHidden(seeMore);
  });
  teamMembers.forEach(function(teamMember) {
    removeClassHidden(teamMember);
  });
  teamMembersTablet.forEach(function(teamMemberTablet) {
    removeClassHidden(teamMemberTablet);
  });
  teamDescriptions.forEach(function(teamDescription) {
    addClassHidden(teamDescription);
  });
  teamDescriptionsTablet.forEach(function(teamDescriptionTablet) {
    addClassHidden(teamDescriptionTablet);
  });
};

window.collapseMemberDescriptionMobile = function(position, memberPosition) {
  teamMembersMobile.forEach(function(teamMemberMobile) {
    if (teamMembersMobile[position] === teamMemberMobile) {
      teamMemberMobile.classList.add("col-12");
      teamMemberMobile.classList.remove("col-6");
      if (memberPosition !== 11)
        addClassHidden(teamMembersMobile[memberPosition]);
    } else {
      teamMemberMobile.classList.remove("col-12");
      teamMemberMobile.classList.add("col-6");
      removeClassHidden(teamMemberMobile);
    }
  });
};

function addClassesToTeam(position, position2, position3) {
  removeClassHidden(teamDescriptions[position]);
  addClassHidden(teamMembers[position2]);
  addClassHidden(teamMembers[position3]);
}

function calculateExpansion(position, startPosition, endPosition) {
  removeClassHidden(teamDescriptions[position]);
  for (i = startPosition; i < endPosition; i++) {
    addClassHidden(teamMembers[i]);
  }
}

function addClassHidden(teamElement) {
  teamElement.classList.add("hidden");
}

function removeClassHidden(teamElement) {
  teamElement.classList.remove("hidden");
}

function calculateExpansionForDesktop(position) {
  if (position % 6 < 3) {
    calculateExpansion(position, position + 1, position + 4);
  } else {
    calculateExpansion(position, position - 3, position);
  }
}

function calculateExpansionForDesktopTeam(position) {
  if (position % 4 < 2) {
    calculateExpansion(position, position + 1, position + 3);
  } else {
    calculateExpansion(position, position - 2, position);
  }
}
